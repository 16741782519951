<template lang="pug">
  .mr-email-input
    .mr-email-input-container
      mr-input.mr-email-input(
        :class="{ 'with-inner-button': withInnerButton }"
        name="emailInput"
        input-type="email"
        required
        v-model="email"
        :v="$v.email"
        :label="label"
        :disabled="disabled"
        :dataMrAss="dataMrAss"
      )
      .inner-button(v-if="withInnerButton")
        slot
    .suggestion-container(v-show="suggested")
      p
        | Did you mean&nbsp;
        span.suggestion.bold.underline(@click="setEmail") {{ suggested }}
        | ?
</template>

<script>
  import MrInput from '../mrInput';

  import { required, email } from 'vuelidate/lib/validators';
  import { TLDValidator } from '@utilities/customValidators';

  let Mailcheck;

  export default {
    name: 'MrEmailInput',

    components: {
      MrInput,
    },

    validations: {
      email: {
        required,
        email,
        TLDValidator,
      },
    },

    props: {
      value: {
        type: String,
        default: null,
      },

      label: {
        type: String,
        default: 'Email',
      },

      valid: {
        type: Boolean,
        default: false,
      },

      disabled: {
        type: Boolean,
        default: false,
      },

      dataMrAss: {
        type: String,
        default: undefined,
      },
    },

    data() {
      return {
        email: null,
        suggested: null,
      };
    },

    computed: {
      withInnerButton() {
        return !!this.$slots.default;
      },
    },

    watch: {
      email() {
        this.$v.$touch();
        this.verify();
        this.$emit('input', this.email);
        this.$emit('update:valid', !this.$v.$invalid);
      },

      value: {
        immediate: true,
        handler(newValue, oldValue) {
          if (newValue !== oldValue) {
            this.email = newValue;
          }
        },
      },
    },

    async mounted() {
      Mailcheck = await import("mailcheck");
    },

    methods: {
      verify() {
        if (!Mailcheck) {
          return;
        }

        Mailcheck.run({
          email: this.email,
          suggested: (suggestion) => {
            if (suggestion.full === email) {
              this.suggested = null;
              return;
            }
            this.suggested = suggestion.full;
          },
          empty: () => {
            this.suggested = null;
          },
        });
      },

      setEmail() {
        this.email = this.suggested;
        this.suggested = null;
        this.$emit('input', this.email);
        this.$emit('update:valid', true);
      },
    },
  };
</script>

<style lang="stylus">
  .mr-email-input
    .mr-email-input-container
      display flex
      flex-direction row
      position relative
      .mr-email-input
        flex-basis 100%
        margin 0 !important

        &.with-inner-button
          input

    .suggestion-container
      flex 1
      margin-top 0.55em
      margin-left 0.55em
      cursor pointer
      text-align left
      p
        margin 0
    @media mq-mobile
      .mr-email-input-container
        display block
      .mr-email-input-container
        .suggested-email
          text-align center
</style>